<template>
  <div class="partiesInterviews">
    <EntitiesInterviews entities="parties" entity="party" />
  </div>
</template>
<script>
import EntitiesInterviews from "../../components/entities/EntitiesInterviews";

export default {
  components: {
    EntitiesInterviews
  }
}
</script>